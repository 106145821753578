// $primary-color: #292666;
// $secondary-color: #364C61;
$primary-color: #e94f1c;
$secondary-color: #1d1d1b;
$table-header: #efefef;

$text-color: #939598;
$text-color-1: #292666;
$text-color-2: #2a3f54;
$text-color-3: #8d939b;
$text-disabled: #acb2ba;
$link-color: #f2f1ff;
$link-color-1: #f8f8f8;
$grey-1: #acb2ba;
$grey-2: #d1d1d1;
$grey-3: #f2f2f2;
$grey-4: #f5f5f5;
$grey-5: #f1f1f1;
$grey-6: #f5f4f7;
$grey-7: #e7e5e5;
$grey-8: #565656;
$white-color: white;
$status-green: #1bd365;
$approver-green: #50ce6c;
$approver-grey: #d5d9dd;
$status-red: #ff3c3c;
$status-yellow: #f2d73a;
$status-orange: #b72121;
$status-blue: #5582ae;
$border-color: #ededed;
$border-color2: #b6b6b4;
$text-primary: #424242;
$text-color-4: #808081;
$grey-8: #dedfe9;
$grey-9: #8c919a;
$grey-10: #00000026;
$chart-deactivate-color: #8884d8;
$black: #000;
$tableRow: rgba(0, 0, 0, 0.04);
$list-active: #f2f1ff;
$input-border-color: rgba(0, 0, 0, 0.15);
$role-permission-cross-background: rgb(255, 189, 173);
$react-select-option-color: hsl(0, 0%, 100%);
$pagination-button-bg-color: #f7d8ce;
$pagination-hover-color: rgba(41, 38, 102, 0.08);
$react-select-placeholder: rgb(51, 51, 51);
$client-listing-content-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;