@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600&display=swap");
@import "../../../style/sass/main.scss";

.k-dialog-wrapper {
  z-index: 99 !important;
}

.dialogsnop .k-dialog {
  width: 46.75rem;
  border-radius: 0.313rem;
}

.Snopfield {
  margin-top: 0.625rem;
}

.dialogsnop .k-window-titlebar {
  border-top-left-radius: 1.25rem;
  border-top-right-radius: 1.25rem;
  background: $white-color;
  padding: 1rem 1.3rem;
}

.dialogsnop .k-window-title {
  text-align: center;
  color: $text-color-2;
  font-size: 1.25rem;
  font-weight: 700;
}

.dialogsnop {
  .section-subheading {
    color: $secondary-color;
  }

  .btnsnop {
    padding: 0 0.438rem;
  }
}

.dialogsnop .k-button-flat {
  padding: 0;
}

.dialogsnop {
  .k-window-titlebar-actions {
    button.k-button {
      span.k-button-icon {
        font-size: 1.625rem;
      }

      span.k-button-icon::before {
        content: "\e11c";
      }
    }
  }
}

.dialogsnop .k-i-x::before {
  color: #acb2ba;
}

.dialogsnop {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;

  .k-dialog-title {
    padding: 0;
    margin: 0;
    display: block;

    .custom-title-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        h3 {
          padding: 0;
          margin: 0;
          font-weight: 400;

          b {
            font-weight: 700;
          }
        }

        p {
          color: $text-color-3;
          margin: 0.313rem 0 0 0;
          padding: 0;
          font-size: 1rem;
        }
      }

      .k-btn1 {
        color: $border-color2;
        font-size: 1.7rem;
        cursor: pointer;
      }
    }
  }

  .k-dialog-title:before {
    content: "";
  }

  .k-window-content {
    border-bottom: 0.063rem solid rgba(0, 0, 0, 0.08);
  }

  .k-window-actions {
    margin: 0;
    flex-flow: inherit;
    align-items: inherit;
    padding: 0.625rem 0.938rem;

    .btnsnop {
      .MuiGrid-root:nth-child(1) {
        text-align: start;

        .btndelete {
          background: #fff;
          padding: 0.9375rem 1.1875rem;
          line-height: 1.25rem;
          border-radius: 0.563rem;
          border: 0.063rem solid;
          color: $text-primary;
          border-color: $text-primary;

          svg {
            color: $grey-8;
          }
        }
      }

      .MuiGrid-root:nth-child(2) {
        text-align: end;
      }
    }
  }

  label.label-colored {
    color: $primary-color !important;

    strong {
      color: $primary-color !important;
    }
  }

  label.label-not-colored,
  label.disabled {
    color: $text-color !important;

    strong {
      color: $text-color !important;
    }
  }

  label.MuiInputLabel-shrink {
    margin-top: 0.3rem;
  }
}

.btnsnop .btncreate {
  background-color: $primary-color;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 0.625rem;
  color: $white-color;
  padding: 0.9375rem 1.1875rem;
  line-height: 1.25rem;
  margin-right: 0.833rem;
}

.btnsnop .btncreate:disabled {
  color: $grey-1;
  background-color: $link-color-1;
  border: 0.063rem solid $grey-2;
}

.btnsnop .btncancel {
  background: $white-color;
  padding: 0.9375rem 1.1875rem;
  line-height: 1.25rem;
  border: 0.063rem solid $primary-color;
  border-radius: 0.625rem;
  color: $primary-color;
  font-size: 1rem;
}

.dialogsnop strong {
  color: $primary-color;
}

@media (max-width: 767px) {
  .dialogsnop .k-dialog {
    width: 100%;
    padding: 10px;
  }
}

.sop {
  color: $primary-color;
}

.btnsop {
  float: right;
}

.btnop {
  float: right;
  background-color: #292666 !important;
  color: #fff !important;
}

.btnsop button {
  background-color: #fff;
  border-color: #292666;
  color: #292666;
}

.snoptabledata {
  padding: 20px;
}

.k-grid thead {
  background: #292666;
  color: #fff;
}

.tablegrid .k-grid-header .k-header {
  vertical-align: middle;
  border-right: 1px solid $border-color;
}

.tablegrid .k-cell-inner>.k-link {
  justify-content: center;
}

.calenderedit .css-i4bv87-MuiSvgIcon-root {
  color: #003980;
}

.error {
  color: red;
}

button:disabled,
button[disabled] {
  background: $link-color-1;

  &:hover {
    cursor: not-allowed;
  }
}

.client-listing-toolbar-right {
  .client-listing-toolbar-right-edit {
    color: $primary-color;
    background: $link-color-1;
    padding: 0.8125rem 0.875rem;
    border-radius: 0.4375rem;
    @include flexCenter;
    @include cursorPointer;
    margin-right: 5px;
  }

  .client-listing-toolbar-right-edit.disabled {
    color: $text-color-3;
    background: $link-color-1;

    &:hover {
      cursor: not-allowed;
    }
  }
}

.deleteicon {
  float: right;
}

.prevent-select.k-disabled {
  pointer-events: inherit !important;
}

.prevent-select {
  .k-dateinput {
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    position: relative;
    cursor: no-drop;
  }

  .k-dateinput::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    display: block !important;
    z-index: 9 !important;
    -webkit-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important;
    cursor: no-drop !important;
  }
}

.k-calendar-navigation .k-calendar-navigation-highlight {
  width: 100%;
  border-width: 1px 0;
  border-style: solid;
  height: 2.4rem;
  box-sizing: border-box;
  position: absolute;
  top: 55%;
  right: 0;
  transform: translateY(-50%);
}