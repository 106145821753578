.tablegrid {

    .k-grid.k-grid-md {
        border: 0.1rem solid $grey-1;
        border-collapse: collapse;
    }

    .k-grid.k-widget,
    .k-grid.k-grid-md,
    .k-table-md {
        font-size: 1.1rem !important;
        border-collapse: collapse;

        th.k-table-th {
            text-overflow: inherit;
            word-break: break-word;
            white-space: normal;
            vertical-align: middle !important;
            border-color: $grey-1 !important;
            border-width: 0 0.1rem 0.1rem 0;
            border-style: solid;

            .k-column-title {
                text-overflow: inherit;
                word-break: break-word;
                white-space: inherit;
                vertical-align: middle !important;
            }

            .k-cell-inner .k-link {
                justify-content: flex-start; // Other flex properties are coming from library. 
            }
        }

        .k-column-title {
            font-size: 1.25rem !important;
            font-weight: 600;
        }

        .k-pager-info {
            display: block !important;
        }

        .k-grid-content {
            overflow: hidden
        }

        .k-grid-header {
            padding: 0;
        }
    }

    .k-table-tbody .k-table-row:first-child td {
        border-top: 0.1rem solid $grey-1 !important;
    }

    .k-table-tbody .k-table-row td {
        border-right: 0.1rem solid $grey-1 !important;
        border-bottom: 0.1rem solid $grey-1 !important;
    }

    .k-table-tbody .k-table-row td:last-child {
        border-right: none !important;
    }

    .k-table-tbody .k-table-row:last-child td {
        border-bottom: none !important;
    }

    .tablegrid-status {
        @include flexCenter;
    }

    .tablegrid-status-inprogress,
    .tablegrid-status-executing,
    .tablegrid-status-executed {
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 1rem 1rem;
        width: 6.25rem;
        text-align: center;
        white-space: normal !important;
        border-radius: 0.625rem;
        font-weight: 600;
        @include flexCenter;

    }

    .tablegrid-status-inprogress {
        border: 0.1rem solid $status-orange;
        color: $status-orange;
        background: #f7e3e3;

    }

    .tablegrid-status-executed {
        border: 0.1rem solid $status-green;
        color: $status-green;
        background: #edf9ed;
    }

    .tablegrid-status-executing {
        border: 0.1rem solid $status-blue;
        color: $status-blue;
        background: #e9f0f7;
    }

    .tablegrid-status-active,
    .tablegrid-status-inactive {
        border-radius: 0.625rem;
        font-weight: 600;
        font-size: 1rem;
        line-height: 1.25rem;
        padding: 1rem 1rem;
        @include flexCenter;
        width: 6.25rem;
    }

    .tablegrid-status-active {
        border: 2px solid $status-green;
        color: $status-green;
    }

    .tablegrid-status-inactive {
        border: 2px solid $status-red;
        color: $status-red;
    }

    .tablegrid-switch {
        text-align: center;
    }

    .tablegrid-heading {
        color: $secondary-color;
        background: $table-header;
        // border: 0.1rem solid $grey-1;
        padding: 1rem 1.3125rem !important;

        .k-icon {
            color: $secondary-color !important;
        }
    }

    .tablegrid-heading-no-sorting {
        .k-link {
            cursor: default;
        }
    }

    input[type=checkbox] {
        accent-color: $primary-color;
        ;
    }
}

.k-pager-numbers .k-link {
    color: $primary-color !important;

    &:hover {
        background-color: $pagination-hover-color !important;
    }
}

.k-link.k-selected {
    color: $white-color !important;
    background-color: $primary-color !important;
    border-radius: 0.5rem;

}