.client-listing {
  width: 100%;
  padding: 0rem;
  background: $grey-4;

  .client-listing-content {
    width: 100%;
    background: $white-color;
    padding: 1.25rem;
  }

  .client-listing-toolbar {
    margin-bottom: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .client-listing-toolbar-left,
  .client-listing-toolbar-right {
    display: flex;
    align-items: center;
  }

  .client-listing-toolbar-left {
    .client-listing-toolbar-left-refresh {
      color: $primary-color;
      background: $link-color-1;
      padding: 0.8125rem 0.875rem;
      border-radius: 0.4375rem;
      @include flexCenter;
      @include cursorPointer;
    }

    .disabled-refresh {
      color: #84839a;
      background-color: #f3f3f3;
      cursor: auto !important;
    }
  }

  .mrg {
    margin-left: 15px;
  }
}

.k-window {
  -webkit-border-radius: 20px !important;
  border-radius: 20px !important;
}

.span1 {
  color: #03045e;
  font-weight: bold;
}

.k-btn1 {
  color: #b6b6b4;
  float: right;
  margin-top: 0;
}

.border2 {
  margin: 1.25rem;
  border: 0.125rem dashed;
  border-radius: 0.625rem;
  color: #b6b6b4;
}

.div2 {
  margin: 20px;
}

.k-span {
  color: #b6b6b4;
  margin-left: 45%;
  margin-top: 1px;
  margin-bottom: 12px;
  font-size: 50px;
}

.kh4 {
  color: #03045e;
  font-weight: bold;
  text-align: center;
}

.kp {
  color: #b6b6b4;
  font-weight: lighter;
  font-size: 14px;
  text-align: center;
}

.custom-form-control {
  label.MuiFormLabel-root {
    color: $text-color;
    font-size: 1rem;
    font-weight: 500;
    padding: 0 0.833rem;
    transform: translate(0.875rem, 0.91rem) scale(1);
    -webkit-transform: translate(0.875rem, 0.91rem) scale(1);
    -moz-transform: translate(0.875rem, 0.91rem) scale(1);
    -ms-transform: translate(0.875rem, 0.91rem) scale(1);
  }

  label.MuiInputLabel-shrink {
    font-size: 1rem;
    color: $primary-color !important;
    transform: translate(0.875rem, -0.8rem) scale(1);
    -webkit-transform: translate(0.875rem, -0.8rem) scale(1);
    -moz-transform: translate(0.875rem, -0.98rem) scale(1);
    -ms-transform: translate(0.875rem, -0.8rem) scale(1);
  }

  .MuiInputBase-root {
    input.MuiInputBase-input {
      color: #000;
      font-size: 1rem;
      line-height: 3.2rem;
      padding: 0rem 1.6rem;
      height: auto;
    }

    fieldset {
      border: 0.125rem solid;
      border-color: rgba(0, 0, 0, 0.15) !important;
      border-radius: 0.5rem;

      legend {
        background-color: $white-color;
        font-size: 1.2rem;
      }
    }
  }
}

.search-custom-form-control {
  span.error {
    font-size: 1rem;
    font-weight: 500;
  }

  .custom-form-control label.MuiInputLabel-shrink {
    color: $text-color !important;
  }
}

.custom-datepicker-control {
  position: relative;

  .k-input-solid:focus-within {
    box-shadow: unset !important;
  }

  label.label {
    position: absolute;
    z-index: 99;
    top: -0.5rem;
    left: 0.688rem;
    background: $white-color;
    padding: 0px 0.938rem;
    color: $text-color !important;
    font-size: 1rem;
    font-weight: 500;

    strong {
      color: $text-color !important;
    }
  }

  label.disabled {
    color: $primary-color !important;
    cursor: no-drop;
  }

  span.k-input.k-datepicker {
    border: 0.125rem solid;
    border-color: rgba(0, 0, 0, 0.15) !important;
    border-radius: 0.5rem;

    span.k-dateinput {
      .k-input-inner {
        color: #000;
        font-size: 1rem;
        line-height: 3.2rem;
        padding: 0rem 1.6rem;
        height: auto;
      }
    }

    span.k-disabled {
      color: $text-disabled;
      cursor: no-drop;
    }

    button.k-button {
      padding: 0.25rem 1.313rem;
      border-left: 0.125rem solid rgba(0, 0, 0, 0.15) !important;

      span {
        font-size: 1.3rem;
      }
    }

    button.k-button.custom_toggle_calendar_icon {
      padding: 0 0.9rem;
      border: none !important;
      background: transparent;
      color: $secondary-color;

      span.k-icon.k-i-calendar.k-button-icon {
        display: none;
      }

      span.k-button-text {
        display: none;
      }
    }
  }

  span.error {
    font-size: 1rem;
    font-weight: 500;
  }
}

.k-datepicker-popup .k-calendar-infinite .k-calendar-navigation ul {
  li:first-child {
    margin-top: 1.4rem;
  }
}

.k-calendar .k-calendar-td.k-selected .k-calendar-cell-inner,
.k-calendar .k-calendar-td.k-selected .k-link {
  background-color: $primary-color !important;
}
