@import "./../../../style/sass/main.scss";

.snop-kendo-custom-dialog {
  font-family: $fontFamily;
  z-index: $zindex-dialog !important;

  .k-dialog {
    // header
    padding: 1.25rem;
    overflow: hidden;

    .k-dialog-titlebar-actions {
      display: none;
    }

    .k-dialog-titlebar {
      background-color: $white-color;
      padding: 1.2rem 0;
      margin: -1.25rem 0 0 0;

      .k-dialog-title {
        padding: 0;
        margin: 0;
        display: block;
        .custom-title-bar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          div {
            h3 {
              padding: 0;
              margin: 0;
              font-weight: 400;
  
              b {
                font-weight: 700;
              }
            }
  
            p {
              color: $text-color-3;
              margin: 0.313rem 0 0 0;
              padding: 0;
              font-size: 1rem;
            }
          }
          .k-btn1 {
            color: $border-color2;
            font-size: 1.7rem;
            cursor: pointer;
          }
        }
      }
      .k-dialog-title:before {
        content: '';
      }

      .k-dialog-titlebar-actions {
        color: $text-disabled;
        border: 0.125rem solid;
        border-radius: 50%;

        button {
          position: relative;
          left: 0.063rem;
          span {
            font-size: 1.2rem;
          }
        }
      }

      .k-dialog-titlebar-actions:hover {
        color: $primary-color;
      }
    }

    // content
    .k-dialog-content {
      padding: 1.5rem 0;
    }

    // footer
    .k-dialog-buttongroup,
    .k-window-actions {
      padding: 0.938rem 1.25rem;
      margin: 0rem -1.25rem -1.25rem -1.25rem;
      background-color: $grey-6;
      border: none;
      border-bottom-left-radius: 1.25rem;
      border-bottom-right-radius: 1.25rem;
      width: auto;
      button {
        width: 9.375rem;
      }
    }

  }
}

.kendo-customD-dialog.k-dialog-confirm {
  .k-dialog-title {
    .custom-title-bar {
      h3.common-heading {
        font-weight: 600 !important;
      }
    }
  }
  .k-dialog-content {
    p.paragrpah-content {
      color: $text-primary;
      font-size: 1rem;
      line-height: 1.5rem;
      padding: 0rem;
      margin: 0rem;
    }
  }
  .k-dialog {
  .k-dialog-titlebar {
  .k-dialog-actions {
     display: none;
  }
  }
  .k-dialog-buttongroup {
    margin: 0rem -0.938rem  -0.938rem;
  }
}
}

.MuiModal-root{
  z-index: 99999 !important;
}

.dialog-title {
  margin-right: 0.625rem;
}