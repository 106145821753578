// Font weights
$regular: 400;
$medium: 500;
$semiBold: 600;
$bold: 700;
$zindex-dialog: 999;
$zindex: 9999;
$zindex-toaster: 99999;
$zindex-chartTooltip: 9999999999999;
$fontFamily: "Montserrat";

// Logo Styling
.logo-3,
.logo-sc {
  font-size: 8rem;
  line-height: 13rem;
  font-weight: $bold;
  // font-family: 'Montserrat';
}

.logo-3 {
  color: $primary-color;
}

.logo-sc {
  color: $secondary-color;
}

.remove-underline {
  text-decoration: none;
}

.w-100 {
  width: 100%;
}

.common-heading {
  color: $primary-color;
  font-size: 1.25rem;
  font-weight: $semiBold;
  line-height: 1.5rem;
}

.section-heading {
  font-weight: $bold;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: $secondary-color;
}

.section-subheading {
  font-weight: $semiBold;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $text-disabled;
}

.grey-heading-500,
.grey-heading-600 {
  color: $grey-2;
  font-size: 1rem;
  line-height: 1.25rem;
}

.grey-heading-500 {
  font-weight: $medium;
}

.grey-heading-600 {
  font-weight: $semiBold;
}

.section-breadcrumbs {
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: $regular;

  .active {
    color: $primary-color;
  }
}

.section-breadcrumbs-a {
  color: $text-disabled;

  &:hover {
    color: $primary-color;
  }
}

// Mui Text Field Global Styling
fieldset.MuiOutlinedInput-notchedOutline {
  border-color: $grey-1 !important;
}

label.MuiInputLabel-root,
.MuiInputLabel-root.MuiInputLabel-formControl.Mui-focused {
  color: $grey-1;
  z-index: 0;
}

.primary-button {
  background: $primary-color;
  color: $white-color;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
  font-size: 1rem;
  font-weight: $semiBold;
  line-height: 1.25rem;

  &:hover {
    color: $white-color;
  }

  &:disabled {
    color: $text-disabled;
    border: 0.063rem solid $grey-2;
  }
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color;
  font-size: 1rem;
  line-height: 1.25rem;
  border-radius: 10px;
  padding: 1rem;
  font-weight: $semiBold;

  &:disabled {
    color: $text-disabled;
    border: 0.063rem solid $grey-2;
  }
}

.cancel-button {
  border: 1px solid $primary-color;
  padding: 0.9375rem 1.375rem;
  color: $primary-color;
  line-height: 1.25rem;
  font-weight: $semiBold;
  border-radius: 0.625rem;
}

.ml-20 {
  margin-left: 1.25rem !important; //20px
}

.ml-10 {
  margin-left: 0.625rem !important; //10px
}

.ml-15 {
  margin-left: 0.938rem !important; //15px
}

.ml-5 {
  margin-left: 0.3125rem !important; //5px
}

.mr-20 {
  margin-right: 1.25rem;
}

.mr-10 {
  margin-right: 0.625rem;
}

.mr-5 {
  margin-right: 0.3125rem !important;
}

.mt-5 {
  margin-top: 0.3125rem !important;
}

.k-grid-header.k-header {
  color: $white-color;
  background: $primary-color;
  border: 1px solid $border-color;
  padding: 28px 21px;
}

.csv-upload-success-li {
  list-style: none;
  color: $status-green;
}

.mySelect__control {
  border-radius: 0.5rem !important;
  border: 0.1rem solid $grey-1 !important;
  box-shadow: none !important;
}

.mySelect__control--is-disabled {
  background-color: $grey-6 !important;
}

.mySelect__value-container {
  height: 3.4375rem;
  padding-left: 0.875rem;
  padding-right: 0.875rem;

  .mySelect__multi-value__label {
    background-color: $table-header;
  }

  .mySelect__multi-value__remove {
    background-color: $table-header;

    &:hover {
      background-color: $role-permission-cross-background;
      color: $primary-color;
    }
  }

  // overflow-y: scroll !important;
}

.mySelect__value-container::-webkit-scrollbar {
  width: 0.313rem;
}

.mySelect__placeholder {
  color: $grey-1;
  font-family: $fontFamily;
}

.mySelect__single-value {
  font-size: 0.875rem;
  color: $black;
  font-family: $fontFamily;
}

.mySelect__menu {
  z-index: 100 !important;

  .mySelect__option--is-focused:active {
    background-color: $grey-3;
  }

  .mySelect__option--is-selected {
    background-color: $primary-color;
  }

  .css-10wo9uf-option:active {
    background-color: white;
  }
}

.admin-toolbar-search,
.snop-toolbar-search {
  .MuiOutlinedInput-notchedOutline.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    bottom: 0rem !important;
    top: -0.46rem !important;
    border: 1px solid $grey-1 !important;
  }

  .MuiInputBase-input.MuiOutlinedInput-input {
    height: 1.5rem;
    padding: 1rem 0.5rem;
  }

  .MuiInputLabel-root {
    margin-top: 0.3rem;
  }
}

.k-pager {
  .k-button {
    color: $primary-color;
  }

  .k-button-flat-primary {
    color: $primary-color;
  }

  .k-selected {
    cursor: inherit;
    z-index: 2;
    border-radius: 0.5rem;
    background-color: $pagination-button-bg-color !important;
  }

  .k-selected::before {
    opacity: 0.2;
  }

  .k-button::before {
    background: currentColor;
    border-radius: inherit;
    bottom: -1px;
    content: "";
    display: none;
    left: -1px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    right: -1px;
    top: -1px;
    transition: opacity 0.2s ease-in-out;
    z-index: 0;
  }

  .k-button::after {
    border-radius: inherit;
    content: "";
    opacity: 0;
    display: none;
    pointer-events: none;
    position: absolute;
    left: -1px;
    right: -1px;
    top: -1px;
    bottom: -1px;
    z-index: 0;
    transition: opacity 0.2s ease-in-out;
  }
}

label.Mui-focused {
  color: $primary-color;
  z-index: 0;
  font-size: 1rem;
  font-weight: 500;
  font-family: $fontFamily;
  margin-top: 0.3rem;
}
