@import "../../style/sass/main.scss";

.k-dialog-wrapper.custom-dialog-loader {
  backdrop-filter: blur(2px);
  .k-overlay {
    background-color: transparent;
    opacity: 1;
  }
  .progress-div {
    min-width: 20rem;
  }
  .k-progressbar-horizontal {
    height: 0.7rem;
  }
  .k-progressbar {
    background-color: $grey-1;
    .k-selected {
      border-color: $white-color;
      color: $white-color;
      background-color: $primary-color;
    }
  }
  .k-window.k-dialog {
    background-color: transparent;
    box-shadow: none;
    color: $white-color;
    border-color: none;
  }

  .typography {
    font-size: 2rem !important;
    color: $primary-color;
    text-align: center;
  }
}
