@import "../../style/sass/main.scss";
@import "../../style/sass/colors";

.event-container {
    background: $table-header;
    padding: 1.4rem 0.5rem;
    border-radius: 0.3rem;
    height: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
    font-size: 1rem;
    font-weight: 600;
    color: $black;
    border-color: transparent !important;

    .failed {
        color: $status-red;
    }

    .success {
        color: $status-green;
    }

    .in-progress {
        color: $status-yellow;
    }

    .not-triggered {
        color: $status-blue;
    }

    button {
        padding: 0.1rem;
    }

    label {
        text-overflow: ellipsis;
    }

    .button-dis {
        color: $text-disabled;
    }

    .button-en {
        color: $status-green;
    }
}

.MuiTooltip-popper {
    z-index: 150000 !important;
}