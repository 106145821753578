@import "../../style/sass/main.scss";

button.btn {
  font-family: $fontFamily;
  min-height: 3.125rem;
  max-height: 3.125rem;
  font-weight: $semiBold;
  line-height: 1rem;
  font-size: 1rem;
  border-radius: 0.625rem;
  padding: 0.9375rem 1.1875rem;
}

button.btn-check:focus+.btn,
button.btn:focus {
  box-shadow: none !important;
}

.primary-button {
  background: $primary-color;
  color: $white-color;

  &:disabled {
    color: $text-disabled;
    background-color: $link-color-1;
    border: 0.063rem solid $grey-2;
  }
}

.primary-button.start {
  svg {
    margin-right: 0.4rem;
    width: 1.5rem;
    height: 1.5rem;
    position: relative;
    top: -0.188rem;
  }
}

.cancel-button {
  border: 0.063rem solid $primary-color;
  color: $primary-color;
}

.secondary-button {
  background: $link-color-1;
  color: $primary-color;

  &:disabled {
    color: $text-disabled;
    border: 0.063rem solid $grey-2;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.125rem;
    margin-top: -0.375rem;
  }
}

.refresh-btn {
  min-height: 3.2rem;
  max-height: 3.2rem;
  background: $link-color-1;
  color: $primary-color;

  &:disabled {
    color: $text-disabled;
    border: 0.063rem solid $grey-2;
  }

  svg {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.125rem;
    margin-top: -0.375rem;
  }
}

.close-btn {
  width: 2rem;
  height: 2rem;
  border: none;
  background: none;
  outline: none;
  padding: 0 !important
}

.delete-btn {
  min-height: 3.125rem;
  max-height: 3.125rem;
  padding: 0rem 1.1875rem;
  border: none;
  background: none;
  outline: none;

  svg {
    width: 100%;
    width: 1.563rem;
  }
}