.calenderhead {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 2rem;
    text-align: center;
    color: #292666;
    margin-top: 20px;
    margin-bottom: 20px;

}

.MuiListItem-button {
    color: #292666 !important;
    font-weight: 600;
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: #fff !important;
    background: #292666 !important;
}

.textFieldBoarder {
    border: 1px solid #e0e0e0;
    border-radius: 0.2rem;
    color: #868686;
    padding: 0.1rem;
}